import { getColor } from '@styles/utils';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100vw;

  background-color: #260e16;
`;
