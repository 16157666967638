exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-articles-tsx": () => import("./../../../src/templates/blog/articles.tsx" /* webpackChunkName: "component---src-templates-blog-articles-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/blog/blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-blog-case-study-tsx": () => import("./../../../src/templates/blog/case-study.tsx" /* webpackChunkName: "component---src-templates-blog-case-study-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog/tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-blog-testers-eye-tsx": () => import("./../../../src/templates/blog/testers-eye.tsx" /* webpackChunkName: "component---src-templates-blog-testers-eye-tsx" */),
  "component---src-templates-blog-video-and-podcast-tsx": () => import("./../../../src/templates/blog/video-and-podcast.tsx" /* webpackChunkName: "component---src-templates-blog-video-and-podcast-tsx" */),
  "component---src-templates-standard-about-tsx": () => import("./../../../src/templates/standard/about.tsx" /* webpackChunkName: "component---src-templates-standard-about-tsx" */),
  "component---src-templates-standard-audits-tsx": () => import("./../../../src/templates/standard/audits.tsx" /* webpackChunkName: "component---src-templates-standard-audits-tsx" */),
  "component---src-templates-standard-career-tsx": () => import("./../../../src/templates/standard/career.tsx" /* webpackChunkName: "component---src-templates-standard-career-tsx" */),
  "component---src-templates-standard-consulting-tsx": () => import("./../../../src/templates/standard/consulting.tsx" /* webpackChunkName: "component---src-templates-standard-consulting-tsx" */),
  "component---src-templates-standard-contact-tsx": () => import("./../../../src/templates/standard/contact.tsx" /* webpackChunkName: "component---src-templates-standard-contact-tsx" */),
  "component---src-templates-standard-home-tsx": () => import("./../../../src/templates/standard/home.tsx" /* webpackChunkName: "component---src-templates-standard-home-tsx" */),
  "component---src-templates-standard-job-tsx": () => import("./../../../src/templates/standard/job.tsx" /* webpackChunkName: "component---src-templates-standard-job-tsx" */),
  "component---src-templates-standard-privacy-policy-tsx": () => import("./../../../src/templates/standard/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-standard-privacy-policy-tsx" */),
  "component---src-templates-standard-tests-tsx": () => import("./../../../src/templates/standard/tests.tsx" /* webpackChunkName: "component---src-templates-standard-tests-tsx" */),
  "component---src-templates-standard-trainings-tsx": () => import("./../../../src/templates/standard/trainings.tsx" /* webpackChunkName: "component---src-templates-standard-trainings-tsx" */)
}

