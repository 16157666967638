import wrapRootElement from './src/AllContextsWrapper';
import { AnimatePresence } from 'framer-motion';

function onPreRouteUpdate({ location, prevLocation }) { }

function onRouteUpdate({ location, prevLocation }) { }

function onClientEntry() { }

function wrapPageElement({ element }) {
    return <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
}

export { wrapRootElement, onRouteUpdate, onPreRouteUpdate, onClientEntry, wrapPageElement };
