/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable prettier/prettier */

/* eslint-disable @typescript-eslint/indent */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AppActionType, IAppState } from '@contextTypes';

import actions from './actions';
import initialState from './state';

function appReducer(state = initialState, action: AppActionType): IAppState {
  switch (action.type) {
    case 'loaded':
      return actions.loaded();
    case 'loading':
      return actions.loading();
    default:
      throw new Error('Wrong action type in app reducer');
  }
}

export default appReducer;
