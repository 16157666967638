import { ILangState, ICurrency } from '@contextTypes';

class LangActions {
  setDefaultLang = (
    state: ILangState,
    { payload: language }: { payload: 'pl' | 'en' | 'de' | 'es' }
  ) => {
    return {
      ...state,
      language
    };
  };

  setLanguage = (
    state: ILangState,
    { payload: language }: { payload: 'pl' | 'en' | 'de' | 'es' }
  ) => {
    return {
      ...state,
      language
    };
  };

  setCurrency = (
    state: ILangState,
    { payload: currency }: { payload: ICurrency }
  ) => {
    return {
      ...state,
      currency
    };
  };
}

const actions = new LangActions();
export default actions;
