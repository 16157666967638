import { DefaultTheme } from 'styled-components';

// kolory od najjasniejszego do najciemniejszego
const theme: DefaultTheme = {
  layout: {
    maxWidth: '1440px',
    paddingX: '108px',
    paddingXMobile: '16px'
  },
  breakpoints: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
    xl: '1439px',
    xxl: '1679px'
  },
  colors: {
    primary: {
      10: '#fde8b3',
      20: '#fbd881',
      30: '#f9c84e',
      40: '#f8b91b',
      50: '#F7B102',
      60: '#de9f02',
      70: '#ad7c01',
      80: '#7c5901',
      90: '#4a3501'
    },
    neutral: {
      10: '#2B343B',
      20: '#414D58',
      30: '#566776',
      40: '#6C8193',
      50: '#A7B3BE',
      60: '#D3D9DF',
      70: '#E2E6E9',
      80: '#F9FAFB',
      90: '#FFFFFF'
    },
    error: {
      10: '#FCF8F7',
      20: '#F9ECEC',
      30: '#ECC7C6',
      40: '#D98F8C',
      50: '#BF4440',
      60: '#993633',
      70: '#732926',
      80: '#4D1B19',
      90: '#391413'
    },
    warning: {
      10: '#FFFCF5',
      20: '#FFF7E5',
      30: '#FFE6B3',
      40: '#FFCD66',
      50: '#FFAB00',
      60: '#CC8900',
      70: '#996700',
      80: '#664400',
      90: '#4D3300'
    },
    success: {
      10: '#F8FCF9',
      20: '#EEF7EF',
      30: '#CBE7CE',
      40: '#96CF9E',
      50: '#52AF5E',
      60: '#408C4A',
      70: '#306938',
      80: '#204625',
      90: '#18341C'
    },
    white: '#FFFFFF',
    black: '#111',
    blue: '#152D84',
    secondaryBrand: '#FF8811',
    secondaryWhite: '#fcfcfc;'
  },
  fonts: {
    family: {
      main: 'Overpass',
      secondary: 'Overpass Mono',
      third: 'Hind'
    },
    size: {
      h1: '40px',
      h2: '32px',
      h3: '28px',
      h4: '20px',
      h5: '18px',
      h6: '16px',
      p: '18px',
      body_20: '18px',
      text_24: '24px',
      text_80: '80px',
      text_12: '12px',
      small: '14px'
    }
  },
  transitions: {
    standard: '.2s all linear',
    time: '.25s',
    type: 'linear'
  }
};

export default theme;
