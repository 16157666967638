/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable prefer-destructuring */
import { IAppState, IAppActions, LangActionType } from '@contextTypes';
import { ThemeProvider } from 'styled-components';

import React, { Dispatch } from 'react';

import {
  CustomerProvider,
  AppProvider,
  WindowProvider,
  useActions,
  AppCtx,
  useContextState,
  LangProvider
} from '@components/contexted';

import { WholePageLoader } from './components/layout/PageLoader';
import { GlobalStyles } from './styles/global';
import './styles/loader.css';
import theme from './styles/theme';

interface ProviderProps {
  children: React.ReactNode;
}

interface ContextsWrapperProps {
  element: React.ReactNode;
}

interface ThemeWrapperProps {
  children: React.ReactNode;
}

const ThemeWrapper = ({ children }: ThemeWrapperProps) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

const OuterProviders: React.FC<ProviderProps> = ({ children }) => (
  <WindowProvider>
    <AppProvider>{children}</AppProvider>
  </WindowProvider>
);

const InnerProviders: React.FC<ProviderProps> = ({ children }) => {
  const { loaded } = useActions<IAppActions>(AppCtx, 'loaded');
  const { imBusy } = useContextState<IAppState>(AppCtx, 'imBusy');

  const onLoad = () => {
    loaded();
  };

  let lang = '';

  if (typeof window !== 'undefined') {
    const storageLang = localStorage.getItem('lang');
    lang =
      storageLang !== null
        ? storageLang
        : window.location.pathname.split('/')[1];
  }

  const onInitLanguage = (dispatch: Dispatch<LangActionType>) => {
    console.log('initLanguage');
    if (lang === '') lang = 'pl';
    dispatch({
      type: 'setDefaultLang',
      payload: lang as 'pl' | 'en' | 'de'
    });
  };

  return (
    <CustomerProvider onLoad={onLoad}>
      <LangProvider onLoad={onInitLanguage}>
        <ThemeWrapper>{imBusy ? <WholePageLoader /> : children}</ThemeWrapper>
      </LangProvider>
    </CustomerProvider>
  );
};

const ContextsWrapper: React.FC<ContextsWrapperProps> = ({
  element
}): JSX.Element => (
  <OuterProviders>
    <InnerProviders>{element}</InnerProviders>
  </OuterProviders>
);

export default ContextsWrapper;
