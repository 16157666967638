import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        outline: none;
    }

    html {
        scroll-behavior: smooth;
        font-size: ${(props) => props.theme.fonts.size.p};
    }

    body {
        font-family: ${({ theme }) => theme.fonts.family.main};
        overflow: hidden;
        overflow-y: scroll;

        display: block;
        width: auto;
        height: auto;

        &.blocked {
            overflow-y: hidden
        }

        h1{
            font-size: ${(props) => props.theme.fonts.size.h1};
            font-family: ${(props) => props.theme.fonts.family.secondary};
            font-weight: 600; 
        }

        h2{
            font-size: ${(props) => props.theme.fonts.size.h2};
            font-family: ${(props) => props.theme.fonts.family.secondary};
            font-weight: 700;
        }

        h3{
            font-size: ${(props) => props.theme.fonts.size.h3};
            font-family: ${(props) => props.theme.fonts.family.secondary};
            font-weight: 700;
        }

        h4{
            font-size: ${(props) => props.theme.fonts.size.h4};
            font-family: ${(props) => props.theme.fonts.family.secondary};
            font-weight: 700;
        }

        h5{
            font-size: ${(props) => props.theme.fonts.size.h5};
            font-family: ${(props) => props.theme.fonts.family.secondary};
            font-weight: 700;
        }

        h6{
            font-size: ${(props) => props.theme.fonts.size.h6};
            font-family: ${(props) => props.theme.fonts.family.main};
            font-weight: 400;
        }

        p{
            font-size: ${(props) => props.theme.fonts.size.p};
            font-family: ${(props) => props.theme.fonts.family.main};
            font-weight: 400;
            line-height: 24px;
        }

        small{
            font-size: ${(props) => props.theme.fonts.size.small};
            font-family: ${(props) => props.theme.fonts.family.main};
        }
    }

    #___gatsby {
        display: block;
        margin: auto;
    }

    .loader {
    position: fixed;
    background: $white;
    color: $main_navy;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000 * 1000;
    display: flex;
    justify-content: center;
    align-items: center; 
}
`;