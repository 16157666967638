import { ILangState } from '@contextTypes';

const initialState: ILangState = {
  language: 'pl',
  currency: {
    label: 'zł',
    name: 'PLN'
  }
};

export default initialState;
