import { ICustomerContext } from '@contextTypes';

import React from 'react';

import initialState from './state';

const CustomerCtx = React.createContext<ICustomerContext>({
  state: { ...initialState },
  dispatch: () => null
});

export default CustomerCtx;
