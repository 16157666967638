import { getColor } from '@styles/utils';

import BeatLoader from 'react-spinners/BeatLoader';

import * as S from './styles';

interface IPageLoader {
  size: number;
}

const PageLoader = ({ size }: IPageLoader): JSX.Element => (
  <BeatLoader size={size} color={getColor('primary', '50')} loading />
);

export const WholePageLoader = (): JSX.Element => (
  <S.LoaderWrapper>
    <BeatLoader size={100} color={getColor('white')} loading />
  </S.LoaderWrapper>
);

export default PageLoader;
