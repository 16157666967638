/* eslint-disable prettier/prettier */

/* eslint-disable */
import { ILangState, LangActionType } from '@contextTypes';

import actions from './actions';
import initialState from './state';

const appReducer = (
  state = initialState,
  action: LangActionType
): ILangState => {
  switch (action.type) {
    case 'setDefaultLang':
      return actions.setDefaultLang(state, action);
    case 'setLanguage':
      return actions.setLanguage(state, action);
    case 'setCurrency':
      return actions.setCurrency(state, action);
    default:
      throw new Error('Wrong action type in window reducer');
  }
};

export default appReducer;
